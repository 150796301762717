const env = process.env.NODE_ENV
// 开发环境运行命令：npm run serve
// 生产环境打包命令：npm run pro
// 测试环境打包命令：npm run build
const IP = {
  development: { // 本地开发环境
    baseIP: 'https://dev.spbcn.org/erp_test/'
    // baseIP: 'http://192.168.2.97:8091/erp/'
    // baseIP: 'http://192.168.2.160:8092/erp_test/'
  },
  production: { // 生产环境
    baseIP: 'https://dev.spbcn.org/erp/'
  },
  test: { // 测试环境
    baseIP: 'https://dev.spbcn.org/erp_test/'
    // baseIP: 'http://47.93.178.140:9666/applets_dev/'
  }
}
export default IP[env]
